<template>
 <div class="contact-form">
   <div v-if="activation.subcategories && activation.subcategories.length">

     <div class="contact-form__input-block-top">
       <span class="input-block-header">{{`Persons onsite for ${activation.title || activation.activityName}`}}</span>
       <Input label="First Name *" v-model="activation.personInfo.contactFirstName"/>
       <Input label="Last Name *" v-model="activation.personInfo.contactLastName"/>
       <Input label="Email *" v-model="activation.personInfo.contactEmail"/>
       <InputPhone
           label="Mobile *"
           :value="activation.personInfo.contactPhone"
           @input="(activation.personInfo.contactPhone = $event)"
           @country="(activation.personInfo.phoneCountry = $event)"
       />
     </div>



     <div class="contact-form__input-block-bottom">
       <span class="input-block-header">{{`Number of people *`}}</span>
       <Input
         type="number"
         min="0"
         max="10000"
         v-for="item in activation.subcategories"
         :label="item.title"
         v-model="item.amount"
         v-bind:key="item.subcategoryId"
       />
     </div>

     <div>
       <div class="contact-form__input-block-bottom mt-5">
         <InputFiles
           label="Upload activation concept"
           :acceptFormats="conceptAcceptFormats"
           :limit="1"
           :maxSize="0"
           v-model="activation.concepts"
         >
           <template v-slot:hint="slot">
             <div class="file-hint">
               <p>Document limit: 1 file</p>
               <p>File type: {{ conceptAcceptFormats.map(format => format.toUpperCase()).join(', ') }}</p>
             </div>
           </template>
         </InputFiles>
       </div>
     </div>
   </div>
   <div v-else> {{`No subcategories for ${activation.title} activation`}} </div>
   <hr class="mt-5" v-if="showSeparator"/>
 </div>
</template>

<script>
import Input from "@/components/Inputs/Input.component";
import InputPhone from "@/components/Inputs/InputPhone.component";
import InputFiles from "@/components/Inputs/InputFiles.component.vue";

export default {
  name: "ContactForm.component",
  components: {
    Input, InputPhone, InputFiles,
  },
  props: ['activation', 'showSeparator'],
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    chaperones: '',
    conceptAcceptFormats: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'odt'],
  })
}
</script>

<style lang="scss" scoped>

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-block-header {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
}

.contact-form {
  ::v-deep .InputLayout {
    margin-bottom: 10px;
  }
  ::v-deep .InputLabel__label {
    width: auto;
    line-height: 14px;
  }
  ::v-deep .Input__input {
    outline: none;
    width: 80%;
    padding: 8px;
  }

  ::v-deep .headline-component {
    margin-bottom: 15px;
  }

  &__input-block-top {
    margin-bottom: 30px;
  }

  &__input-block-bottom {
    .headline-component {
      position: static;
    }
  }
}

.file-hint {
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;

  .red-text {
    color:#ff0000;
    font-weight: 600;
  }
}
</style>

<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DDMCAF-Tab",
  props: {
    title: {
      required: true,
      default: ''
    },
    name: {
      required: true,
      default: ''
    },
    active: {
      default: false
    }
  },
  data: () => ({
    isActive: false
  }),
  mounted() {
    this.isActive = this.active;
  }
}
</script>

<style scoped>

</style>
<template>
<div class="sponsor-activation-summary">
  <Section>
    <div class="sponsor-activation-summary__section-success-request" v-if="isAccreditationCreatedSuccessful">
      <Headline
        :class="[
            'sponsor-activation-summary__section-headline',
            'sponsor-activation-summary__section-headline_centered'
          ]"
        :size="3"
      >
        Your request was submitted successfully!
      </Headline>
      <div class="sponsor-activation-summary__section-check-container">
        <img
          class="sponsor-activation-summary__section-check"
          :src="$static.icons.check"
          alt="icon"
        />
      </div>
    </div>
    <div v-else class="sponsor-activation-summary__match-info">
      <div class="sponsor-activation-summary__match-info-wrapper">
        <Headline class="event-information__activations-header" size="4">
          Match Information
        </Headline>
        <div class="sponsor-activation-summary__match-info-block">
          <div class="sponsor-activation-summary__match-info-title">Matchday</div>
          {{getFrom('eventInfo.matchDay.optionLabel')}}
        </div>

        <div class="sponsor-activation-summary__match-info-block">
          <div class="sponsor-activation-summary__match-info-title">Match</div>
          {{getFrom('eventInfo.match.title')}}
        </div>
        <div class="sponsor-activation-summary__match-info-block">
          <div class="sponsor-activation-summary__match-info-title">Venue</div>
          {{getFrom('eventInfo.match.venue.localTitle')}}
        </div>
      </div>

      <div class="sponsor-activation-summary__activation-info-wrapper">
        <Headline class="event-information__activations-header" size="4"> Activation & Contact person information </Headline>

        <SummaryForm
          v-for="(activation, index) in selectedActivations"
          :activation="activation"
          :activationIndex="index"
          :errors="errors"
        />
      </div>
    </div>
  </Section>

  <div :class="isAccreditationCreatedSuccessful ? 'space-around btn-wrapper ' : 'space-between btn-wrapper '">
    <template v-if="isAccreditationCreatedSuccessful">

      <span/><span/><span/><span/>

      <Button type="primary" @click="goToAccreditationRequest()">
        Request Accreditation
      </Button>

      <Button type="outline-primary" @click="goToActivationsList()">
        Back to Activation Requests
      </Button>

      <span/><span/><span/><span/>

    </template>

    <template v-else>
      <Button type="secondary" @click="changeTab('ActivationInfo')">
        Back
      </Button>

      <Button @click="postSponsorActivation(selectedActivations)"
              v-if="!showLoader"
      >
        Submit request
      </Button>
      <pulse-loader :loading="showLoader" :color="loaderColor"></pulse-loader>
    </template>
  </div>
</div>
</template>

<script>
import Headline from "@/components/Headline/Headline.component";
import Section from "@/components/Section/Section.component";
import Button from "@/components/Button/Button.component";
import SummaryForm from "../components/SummaryForm.component";
import get from "lodash.get";
import set from "lodash.set";
import icon from "@/assets/DDMCAF/images/check.svg";
import {DtoApiPostSponsorActivations} from "@/classes/dto/api/sponsor/dto.api.post-sponsor-activation.request";
import RouterNames from "@/router/route.names";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {PhoneNumberUtil} from "google-libphonenumber";

export default {
  name: "SponsorActivationSummary.view",
  components: { Headline, SummaryForm, Section, Button, PulseLoader },
  data: () => ({
    api: new DtoApiPostSponsorActivations(),
    request: null,
    showLoader: false,
    loaderColor: "#00A9A0",
    errors: {},
  }),
  computed: {
    isAccreditationCreatedSuccessful() {
      if (!this.request) return false;
      return (this.request.$status === 200);
    },
    eventInfo() { return get(this, '$store.state.sponsorActivation.eventInfo') },
    selectedActivations() { return get(this, '$store.state.sponsorActivation.selectedActivations') },
  },
  methods: {
    goToActivationsList() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.SponsorActivationRoutes.SponsorActivationList
      this.$router.push({name});
    },
    goToAccreditationRequest() {
      //prefill matchDay and match
      const matchDay = get(this, '$store.state.sponsorActivation.eventInfo.matchDay', null)
      const match = get(this, '$store.state.sponsorActivation.eventInfo.match', null)
      this.$store.state.accreditationRequest.eventInfo.matchDay = matchDay
      this.$store.state.accreditationRequest.eventInfo.match = match

      const name = RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequest
      this.$router.push({name});
    },
    createMoreActivations() {
      this.$emit('tabchange', 'EventInfo')
      this.$store.commit('sponsorActivation/clearState')
    },
    changeTab(tab) { this.$emit('tabchange', tab) },
    getMobileWithCountryCode(personInfo)
    {
      const util = PhoneNumberUtil.getInstance();

      let result = ''

      try {
        const country = personInfo.phoneCountry.iso2;
        const parsed = util.parse(personInfo.contactPhone, country);
        const code = parsed.getCountryCode();
        const number = parsed.getNationalNumber();
        result = '+' + code + number
      } catch (error) {
        result = personInfo.phoneCountry.dialCode + personInfo.contactPhone
      }

      return result;
    },

    async postSponsorActivation(selectedActivations) {
      const organizationId = get(this, '$store.state.user.account.id');
      const matchId = get(this, '$store.state.sponsorActivation.eventInfo.match.matchId', null);
      const bookedActivities = selectedActivations.map(item => {
        const subcategories = item.subcategories.map(item => ({id: item.subcategoryId, amount: item.amount}))
        let conceptEncoded, conceptName;
        if(item.concepts && item.concepts.length >= 1) {
          conceptEncoded = item.concepts[0].url;
          conceptName = item.concepts[0].filename.split('.').slice(0, -1).join('.');
        }
        let result = {
          competitionActivityId: item.competitionActivityId,
          subcategories,
          conceptEncoded: conceptEncoded || null,
          conceptName: conceptName || null,
          ...item.personInfo,
        }

        result.contactPhone = this.getMobileWithCountryCode(item.personInfo)

        return result
      })

      const params = {organizationId, matchId, bookedActivities}
      this.showLoader = true
      this.request = await this.api.$post(params)
      if (this.request.$status === 422) {
        this.errors = this.request.$response.data.data.errors;
      }
      this.showLoader = false
    },
    getFrom(path) { return get(this, path, '-') },
  },
  created() {
    set(this, '$static.icons.check', icon);
  }
}
</script>

<style lang="scss" scoped>
.sponsor-activation-summary {
  &__section {
    &-success-request {
      height: 300px;
      display: flex;
      flex-direction: column;
    }
    &-headline {
      display: flex;

      &_centered {
        justify-content: center;
      }
    }

    &-check {
      width: 130px;
      margin: auto;

      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 1;
      }
    }
  }

  &__match-info-wrapper{
    margin-bottom: 50px;
  }
  &__match-info-block {
    display: flex;
    margin-bottom: 15px;
  }
  &__match-info-title {
    width: 300px;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: 10px;
  }

  .btn-wrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div .button-component:first-child {
      margin-right: 20px;
    }
  }

  .space-around {
    justify-content: space-around;
  }
  .space-between {
    justify-content: space-between;
  }
}
</style>

<template>
  <section class="InputPhone form-group row">
    <div class="col-sm-4">
    <InputLayout
      :input-id="$static.input.id"
      :label="label"
      :subtext="subtext"
      :arrangement="arrangement"
      :error="error"
    > </InputLayout>
    </div>
    <div class="col-sm-8">
      <VueTelInput
        ref="shit"
        class="InputPhone__phone"
        mode="international"
        :value="value"
        :inputOptions="telInputOptions"
        @input="eventEmitterInput($event, parseValue)"
        @country-changed="handleCountryChange"
      >
        <template #arrow-icon="{open}">
          <span class="vti__dropdown-arrow">{{open ? '▲' : '▼'}}</span>
        </template>
      </VueTelInput>
    </div>
  </section>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import InputLayout from "@/components/Inputs/components/InputLayout.component";
import InputMixin from "@/components/Inputs/mixins/input.mixin";
import LayoutMixin from "@/components/Inputs/mixins/layout.mixin";
import 'vue-tel-input/dist/vue-tel-input.css';


export default {
  name: 'DDMCAF-InputPhone',
  components: {InputLayout, VueTelInput},
  mixins: [InputMixin, LayoutMixin],
  props: {
    value: {type: null}
  },
  data(){
    return {
      country: null,
    };
  },
  computed: {
    telInputOptions(){
      return {
        showDialCode: true,
        autocomplete: 'on',
        id: this.$static.input.id,
        name: this.$static.input.id
      };
    },
  },
  methods: {
    parseValue(value){
      if(this.country && this.country.dialCode && value === `+${this.country.dialCode}`){
        return '';
      }
      return value;
    },
    handleCountryChange($event){
      this.country = $event;
      this.$emit('country', $event);
    }
  }

}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
.InputPhone {
  &__phone {
    box-shadow: none !important;
    padding: 12px 16px 12px 4px;
    width: 250px;
    height: 43px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 12px !important;
    border: 1px solid #D8DAE3 !important;
    background-color: white;
    font-weight: 500;

    .vti__input {
      font-size: 12px;
      border-radius: 12px;
      color: #495057;
      font-weight: 700 !important;
      padding: 0 0 0 1px;
      margin: 0;

      &::placeholder {
        color: #AFB1BC;
        font-weight: 600;
      }
    }
    .vti__dropdown {
      //display: none;
      background-color: white !important;
      border-right: 1px solid #e8e8e8 !important;
      border-right: none !important;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      outline: none !important;
    }

    .vti__dropdown-arrow { user-select: none }
    .vti__dropdown-list {
      width: 510px !important;
      z-index: 100;
    }
  }
}
</style>

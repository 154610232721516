<template>
  <div class="event-information">
    <div class="alert alert-warning" role="alert">
      This Activation requires an accreditation. Please request an accreditation after submitting this request.
    </div>

    <Section>
      <Headline class="event-information__page-header"  size="3">Please request your Partner activation</Headline>

      <div class="event-information__content row">
        <div class="event-information__activations col-sm-4">
          <Headline class="event-information__activations-header" size="4">
            Select your activation(s)
          </Headline>

          <Checkbox
            v-for="item in activations"
            :name="item.title"
            :value="item"
            :label="item.title"
            :isChecked="isChecked(item)"
            @click="selectActivation(item)"
          />
        </div>
        <div class="event-information__participants col-sm-8">
          <Headline class="event-information__participants-header" size="4">
            Contact person on-site
          </Headline>

          <ContactForm
            v-if="selectedActivations.length"
            v-for="(activation, i) in selectedActivations"
            :activation="activation"
            :showSeparator="i < selectedActivations.length - 1"
          />
        </div>
      </div>

      <div class="btn-wrapper">
        <Button
          type="secondary"
          @click="changeTab('EventInfo')"
        >
          Back
        </Button>

        <p class="policy-text" v-html="policyText"></p>

        <Button :disabled="!isValid() ? 'disabled' : null" @click="changeTab('Summary')">
          Continue
        </Button>
      </div>
    </Section>
  </div>
</template>

<script>
import Button from "@/components/Button/Button.component";
import Headline from "@/components/Headline/Headline.component";
import Section from "@/components/Section/Section.component";
import Checkbox from "@/components/Checkbox/Checkbox.component";
import ContactForm from "../components/ContactForm.component";

import get from "lodash.get";

export default {
  name: "ActivationInformation",
  components: {
    Button,
    Headline,
    Section,
    Checkbox,
    ContactForm
  },
  data: () => ({}),
  computed: {
    activations() { return get(this, '$store.state.sponsorActivation.activations') },
    selectedActivations() { return get(this, '$store.state.sponsorActivation.selectedActivations') },
    policyText() {
      return 'By clicking <b>CONTINUE</b> and submitting this form I confirm that' +
          ' the information either relates to myself and/or I have the authority' +
          ' and permission to submit the data on behalf of the person named above' +
          ' and such persons have been made aware of, and agree to, the terms of the' +
          ' <a href=\''+window.boUrl+'/policy'+'\' target="_blank">AFC Data Protection Policy.</a>'
    }
  },
  methods: {
    isChecked(checkedItem) {
       return this.selectedActivations.filter(item => item.activityId === checkedItem.activityId).length
    },
    changeTab(tab) { this.$emit('tabchange', tab) },
    selectActivation(data) { this.$store.commit('sponsorActivation/selectActivation', data) },
    isValid() {
      const activations = get(this, '$store.state.sponsorActivation.selectedActivations');
      // no activations
      if (!activations || activations.length === 0) {
        return false;
      }

      for (let activation of activations) {
        // activation missing contact info or participant amounts
        if (!activation.personInfo.contactEmail || !activation.personInfo.contactPhone ||
            !activation.personInfo.contactFirstName || !activation.personInfo.contactLastName ||
            activation.subcategories.filter((s) => !s.amount || s.amount < 0).length > 0) {
          return false;
        }
      }
      return true;
    }
  }
}
</script>

<style lang="scss" scoped>
.event-information {
  &__content {
    display: flex;
  }

  &__activations-header,
  &__participants-header {
    margin-bottom: 40px;
  }

  &__participants {
    padding-left: 40px;
  }

  &__page-header {
    margin-bottom: 70px;
  }

  ::v-deep .checkbox {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .contact-form {
    margin-bottom: 60px;
  }

  .contact-form:last-child {
    margin: 0;
  }

  .btn-wrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div .button-component:first-child {
      margin-right: 20px;
    }
  }
}
</style>

<template>
  <section class="InputFile form-group row">
    <div class="col-sm-4">
    <InputLayout
      :input-id="$static.input.id"
      :label="label"
      :subtext="subtext"
      :arrangement="arrangement"
      :error="error"
    >
    </InputLayout>
    </div>
    <div class="col-sm-8">
      <div class="InputFile__content">
        <div
          ref="dropzone"
          class="InputFile__content-box"
          :class="{'InputFile__content-box_hover': hovered}"
          @click="callInput()"
          @drop.prevent="callDrop($event)"
          @dragenter.prevent="hovered = true"
          @dragover.prevent
          @dragend="hovered = false"
          @dragleave="hovered = false"
        ></div>
        <input
          class="InputFile__input"
          ref="input"
          type="file"
          multiple
          :accept="accept"
          :id="$static.input.id"
          @change="inputChange($event)"
        />
        <Button class="InputFile__content-button" type="secondary" @click="callInput()">Choose File</Button>
      </div>
      <slot :name="`hint`" > </slot>
      <div class="InputFile__files row" v-for="(file, index) in files" :key="index">
        <div :class=" {'col-md-8': true }">
          <a :href="file.url" target="_blank"> {{ file.filename }}</a>
        <p class="text-danger mt-1 mb-1 InputLayout__error" v-if="errorState[index] && errorState[index].error">{{errorState[index].errorMessage}}</p>
        </div>
        <div class="col-md-4">
          <Button
              @click="deleteFile(index)"
              icon="REMOVE_OUTLINE"
              form="link"
              type="secondary"
              color="#ff0000"
          />
        </div>
      </div>


    </div>

  </section>
</template>

<script>
import InputLayout from "@/components/Inputs/components/InputLayout.component";
import InputMixin from "@/components/Inputs/mixins/input.mixin";
import LayoutMixin from "@/components/Inputs/mixins/layout.mixin";
import Button from '@/components/Button/Button.component';

export default {
  name: 'DDMCAF-InputFile',
  components: {InputLayout, Button},
  mixins: [InputMixin, LayoutMixin],
  data: () => ({ files: null, hovered: false, errorState: []}),
  props: ({
    value: Array,
    limit: Number,
    maxSize: {
      type: Number,
      default: 512, //kb
    },
    acceptFormats: {
      type: Array,
      default() {
        return ['pdf', 'png', 'jpeg'];
      },
    },
  }),
  created() {
    this.files = this.value;
  },
  watch: {
    value(value) {this.files = value}
  },
  computed: {
    accept() {
      return this.acceptFormats.map(format => {
        switch(format) {
          case 'png':
            return 'image/x-png';
          case 'jpg':
          case 'jpeg':
            return 'image/jpeg';
          case 'doc':
            return 'application/msword';
          case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          case 'ppt':
            return 'application/mspowerpoint';
          case 'pptx':
            return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
          case 'odt':
            return 'application/vnd.oasis.opendocument.text';
          case 'pdf':
            return 'application/pdf';
        }
      }).filter(mime => mime).join(', ');
    },
  },
  methods: {
    callInput() {
      this.$refs.input.click();
    },
    callDrop(event) {
     if (!event.dataTransfer.items.length) return;
      this.inputHandleFile(event.dataTransfer.items);
    },
    inputChange(event) {
      this.inputHandleFile(event.target.files);
    },
    inputHandleFile(files) {
      if (!files) return;
      const maxSize = this.maxSize * 1024;
      let uploadFiles = this.files ?? [];
      const limit = this.limit || 0;
      Promise.all(Array.from(files).map((file) => {
        if(limit && uploadFiles.length >= limit){
          return;
        }
        const name = file.name;
        let fileErrorState = {
          errorMessage : '',
          error : false,
        };
        if (maxSize && file.size > maxSize) {
          fileErrorState.errorMessage = 'File size too large';
          fileErrorState.error = true;
        }
        this.errorState.push(fileErrorState);
        let reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = function (event) {
            if(limit && uploadFiles.length >= limit){
              resolve();
              return;
            }
            uploadFiles.push({
              documentId: null,
              filename: name,
              url: event.target.result,
            });
            resolve();
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })).then(() => {
        this.files = uploadFiles;
        this.$emit('input', this.files);
      });
    },
    deleteFile(index){
      this.files.splice(index, 1);
      this.errorState.splice(index, 1);
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";
.InputFile {
  &__input {
    display: none;
  }
  &__files{
    .button-component{
      margin: 0;
      padding: 5px 0;
    }
    a{
      text-underline: none;
      color: #0A6AA1;
      font-weight: 700;
      font-size: 12px;
    }

  }
  &__content {
    display: flex;
    align-items: center;


    &-box {
      width: 200px;
      height: 43px;
      background-color: white;
      border: 1px solid #D8DAE3;
      border-radius: 12px;

      &_hover {
        background-color: #F4F6FC;
      }
    }

    &-image {
      width: 200px;
      border: 1px solid #D8DAE3;
      border-radius: 12px;
    }

    &-button {
      margin-left: 20px
    }
  }
}
</style>

<template>
  <section class="Section">
    <slot/>
  </section>
</template>

<script>
export default {
  name: 'DDMCAF-Section'
}
</script>

<style lang="scss">
.Section {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 32px 24px;
  margin-bottom: 32px;
}
</style>

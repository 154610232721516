<template>
  <div class="event-information">
    <Section>
      <Headline size="3">Event Information</Headline>

      <div class="event-information-fields row">
        <div class="col-md-12">
          <InputDropdown
            placeholder="Matchday"
            :allow-empty="false"
            :options="matchDaysList"
            trackLabel="optionLabel"
            label="Matchday"
            v-model="matchDay"
          />

          <InputDropdown
            placeholder="Select match"
            :disabled="!matchDay"
            :allow-empty="false"
            :options="matchesList"
            trackLabel="name"
            label="Match"
            v-model="match"
          />
        </div>

        <div v-if="this.match" class="col-md-4 venue-label">Venue</div>
        <div v-if="this.match" class="col-md-8 venue-value-text">
          {{  this.match.venue ? this.match.venue.localTitle : '' }}
        </div>

        <div v-if="this.match" class="col-md-4 venue-label mt-4">Match Kick-off Time (UTC+8)</div>
        <div v-if="this.match" class="col-md-8 venue-value-text mt-md-4">
          {{  this.match ? this.getMatchKickoffTime(this.match.startDateTime) : '' }}
        </div>
      </div>
    </Section>

    <div class="btn-wrapper">
      <Button
        :disabled="!matchDay || !match"
        type="primary"
        @click="changeTab('ActivationInfo')"
      >
        Continue
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button/Button.component";
import Headline from "@/components/Headline/Headline.component";
import InputDropdown from '@/components/Inputs/InputDropdown.component';
import Section from '@/components/Section/Section.component';

import get from "lodash.get";
import moment from "moment";

export default {
  name: "AccreditationEventInformation.view",
  components: {
    Button,
    Headline,
    InputDropdown,
    Section
  },
  data: () => ({}),
  computed: {
    matchDaysList() { return get(this, '$store.state.event.matchDaysList') },
    matchesList() { return get(this, '$store.state.event.matchesList') },
    matchDay: {
      get: function () { return this.$store.state.sponsorActivation.eventInfo.matchDay },
      set: function (newVal) { this.$store.state.sponsorActivation.eventInfo.matchDay = newVal }
    },
    match: {
      get: function () { return this.$store.state.sponsorActivation.eventInfo.match },
      set: function (newVal) { this.$store.state.sponsorActivation.eventInfo.match = newVal }
    },
  },
  watch: {
    matchDay(newVal) {
      if(newVal) {
        this.getMatches(this.matchDay)
        this.match = ''
      }
    },
  },
  methods: {
    getMatches(data) { this.$store.dispatch('event/getMatches', {...data, requestType: 'sponsor_activation_request'}) },
    changeTab(tab) {
      this.$emit('tabchange', tab)
    },
    getMatchKickoffTime(startDateTime) {
      return moment(startDateTime).add(8, 'hours').format('DD.MM.YYYY HH:mm')
    },
  },
  created() {
    this.$store.dispatch('event/getMatchDays', {hasMatchesForActivationRequest: 1})
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/mixins/ellipsis.mixin";
@import "../../../../styles/mixins/radio.mixin";

.event-information {
  font-size: 12px;

  .venue-label {
    font-weight: bold;
  }

  .venue-value-text {
    padding-left: 1.1rem;
    font-size: 12px;
    font-weight: bold;
  }

  @include radio();
  .radio-group {
    display: flex;

    > div {
      margin-right: 40px;
    }
  }

  .paragraph-component {
    color: #383838;
  }

  .InputDropdown {
    margin-bottom: 20px;
  }

  .btn-wrapper {
    width: 100%;
    text-align: right;
  }

  ::v-deep .InputLabel__label {
    @include ellipsis;
    min-width: 166px;
    max-width: 166px;
    width: 166px;
    font-size: 12px;
    font-weight: bold;
  }

  ::v-deep .InputLayout__slot {
    width: 100%;
    .multiselect {
      width: 100%;
    }
  }

  ::v-deep .multiselect--disabled {
    background: none;

    .multiselect__select {
      background: none;
    }
  }
}
</style>

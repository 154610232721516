<template>
  <div class="tabs-component">
    <div>
      <div class="line" />
      <ul class="tabs-component__tab-list">
        <li
          v-for="(tab, index) in tabs"
          class="tabs-component__tab"
          :class="{ 'is-active': tab.tab === activeTab }"
        >
          <span class="tabs-component__index">{{index + 1}}</span>
          <span>{{ tab.title }}</span>
        </li>
      </ul>
    </div>

    <div class="tabs-component__tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "DDMCAF-Tabs",
  data: () => ({}),
  props: ['tabs', 'activeTab'],
}
</script>

<style lang="scss" scoped>
@import "src/styles/main.scss";

.tabs-component {
  > div {
    position: relative;
  }

  .line {
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background-color: #D8DAE3;
  }

  &__tab-list {
    margin: 40px 0 42px;
    display: flex;
    justify-content: space-between;
  }
  &__tab {
    position: relative;
    z-index: 1;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    background: #F4F6FC;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    box-sizing: border-box;
    padding: 0 24px;

    &.is-active {
      .tabs-component__index {
        background-color: $secondary-color;
      }
    }

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
  &__index {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #9b9b9b;
    color: #fff3cd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  &__tab-content {}
}
</style>

<template>
  <section :class="{ InputDropdown: true, 'form-group': true, row: !!label }">
    <div class="col-sm-4" v-if="label">
      <InputLayout
          :input-id="$static.input.id"
          :label="label"
          :subtext="subtext"
          :arrangement="arrangement"
          :error="error"
      ></InputLayout>
    </div>
    <div :class="{'col-sm-8' : !!label }">
      <Multiselect
          :value="value"
          :label="trackLabel"
          :searchable="searchable"
          :show-labels="false"
          close-on-select
          @input="eventEmitterInput($event)"
          v-bind="{...$props, ...$attrs}"
          :customLabel="customLabel"
          :title="getTooltipValue(tooltip)"
      >
        <template slot="option" slot-scope="option">
          <div :title="option.option[tooltip]">{{ trackLabel ? customLabel(option.option, trackLabel) : option.option }}</div>
        </template>
      </Multiselect>
    </div>
  </section>
</template>

<script>
import InputLayout from "@/components/Inputs/components/InputLayout.component";
import InputMixin from "@/components/Inputs/mixins/input.mixin";
import LayoutMixin from "@/components/Inputs/mixins/layout.mixin";
import Multiselect from 'vue-multiselect';

export default {
  name: 'DDMCAF-InputDropdown',
  components: {InputLayout, Multiselect},
  mixins: [InputMixin, LayoutMixin],
  props: {
    value: {type: null, default: ''},
    tooltip: {type: String, default: ''},
    trackLabel: {type: String, default: ''},
    searchable: {type: Boolean, default: false}
  },
  methods: {
    getTooltipValue() {
      return (this.tooltip && this.value) ? this.value[this.tooltip] : ''
    },
    customLabel(option, label) {
      return label ? this.buildLabel(option, label) : option
    },

    buildLabel(option, label) {
      let result = label

      if (!!label && label.includes('.')) {
        result = option[label.split('.')[0]][label.split('.')[1]]
      } else {
        result = option[label]
      }

      let message = ''
      if (option.$isDisabled) {
        message = " (no matches available yet)"
        if ('isAccessible' in option ) {
          message = ' (not available yet)'
        }
      }

      return result + message
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.InputDropdown {
  .multiselect {
    width: 100% !important;
  }

  .multiselect--active {
    .multiselect__select {
      padding-bottom: 7px;
    }
  }
  .multiselect__tags {
    border: 1px solid #D8DAE3;
    padding-top: 11px;
    padding-left: 10px;
  }
  .multiselect__single {color: #383838}
  .multiselect__placeholder,
  .multiselect__input {
    font-weight: 600 !important;
  }
  .multiselect__input::placeholder {
    font-weight: 600 !important;
    color: #AFB1BC;
  }
  .multiselect__option {
    padding-left: 16px;
    font-size: 12px;
    font-weight: 600 !important;
  }
  .multiselect__option--highlight {
    background-color: $primary-color;
    font-weight: 700 !important;
  }
  .multiselect__placeholder {
    padding-left: 5px !important;
    padding-top: 4px !important;
  }
  .multiselect__select {top: 2px}
  .multiselect, .multiselect__tags, .multiselect__option {min-height: 43px}
  .multiselect, .multiselect__placeholder, .multiselect__input, .multiselect__single, .multiselect__element {font-size: 12px}
  .multiselect__content-wrapper, .multiselect__select {transition-duration: 0s}
  .multiselect__select {height: 35px}
  .multiselect__tags {
    border-radius: 12px;
    font-weight: 700;
  }
  .multiselect__placeholder {
    font-weight: normal;
    padding-left: 3px;
    padding-top: 3px;
  }
  .multiselect--disabled {
    background: transparent;
  }
  .multiselect--disabled .multiselect__select {
    display: none;
  }
}
</style>

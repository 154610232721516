import { render, staticRenderFns } from "./InputPhone.component.vue?vue&type=template&id=1de8dfc4&"
import script from "./InputPhone.component.vue?vue&type=script&lang=js&"
export * from "./InputPhone.component.vue?vue&type=script&lang=js&"
import style0 from "vue-tel-input/dist/vue-tel-input.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./InputPhone.component.vue?vue&type=style&index=1&id=1de8dfc4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="summary-form">
    <div class="summary-form__title">{{ activation.title }}</div>
    <div class="summary-form__wrapper">
      <div class="summary-form__row">
        <div class="summary-form__label">First Name</div>
        <div class="summary-form__info">{{ activation.personInfo.contactFirstName || '-' }}</div>
        <div class="summary-form__errors" v-if="hasError('bookedActivities.' + activationIndex + '.contactFirstName')">
          <ul>
            <li v-for="errorMsg in errors['bookedActivities.' + activationIndex + '.contactFirstName']">
              {{ getErrorMsg(errorMsg) }}
            </li>
          </ul>
        </div>
      </div>

      <div class="summary-form__row">
        <div class="summary-form__label">Last Name</div>
        <div class="summary-form__info">{{ activation.personInfo.contactLastName || '-' }}</div>
        <div class="summary-form__errors" v-if="hasError('bookedActivities.' + activationIndex + '.contactLastName')">
          <ul>
            <li v-for="errorMsg in errors['bookedActivities.' + activationIndex + '.contactLastName']">
              {{ getErrorMsg(errorMsg) }}
            </li>
          </ul>
        </div>
      </div>

      <div class="summary-form__row">
        <div class="summary-form__label">Email</div>
        <div class="summary-form__info">{{ activation.personInfo.contactEmail || '-' }}</div>
        <div class="summary-form__errors" v-if="hasError('bookedActivities.' + activationIndex + '.contactEmail')">
          <ul>
            <li v-for="errorMsg in errors['bookedActivities.' + activationIndex + '.contactEmail']">
              {{ getErrorMsg(errorMsg).replace(/contactEmail/i, 'Email') }}
            </li>
          </ul>
        </div>
      </div>

      <div class="summary-form__row">
        <div class="summary-form__label">Mobile</div>
        <div class="summary-form__info">{{ this.getMobileWithCountryCode(activation.personInfo) || '-' }}</div>
        <div class="summary-form__errors" v-if="hasError('bookedActivities.' + activationIndex + '.contactPhone')">
          <ul>
            <li v-for="errorMsg in errors['bookedActivities.' + activationIndex + '.contactPhone']">
              {{ getErrorMsg(errorMsg).replace(/contactPhone/i, 'Mobile') }}
            </li>
          </ul>
        </div>
      </div>

      <div v-for="(subcategory, index) in activation.subcategories" class="summary-form__row">
        <div class="summary-form__label">{{ subcategory.title || '-' }}</div>
        <div class="summary-form__info">{{ subcategory.amount || '-' }}</div>
        <div class="summary-form__errors" v-if="hasError('bookedActivities.' + activationIndex + '.subcategories.' + index + '.amount')">
          <ul>
            <li v-for="errorMsg in errors['bookedActivities.' + activationIndex + '.subcategories.' + index + '.amount']">
              {{ getErrorMsg(errorMsg) }}
            </li>
          </ul>
        </div>
      </div>

      <div v-for="concept in activation.concepts" class="summary-form__row">
        <div class="summary-form__label">Activation concept file</div>
        <div class="summary-form__info">{{ concept.filename }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import {PhoneNumberUtil} from 'google-libphonenumber';

export default {
  name: "SummaryForm",
  props: ['activation', 'activationIndex', 'errors'],
  methods: {
    hasError(key) {
      if (typeof this.errors[key] !== 'undefined') {
        return this.errors[key];
      }
      return false;
    },
    getErrorMsg(errorMsg) {
      errorMsg = errorMsg.replace(/\.$/, '');
      let errorMsgSplit = errorMsg.split('.');
      return errorMsgSplit[errorMsgSplit.length - 1];
    },
    getMobileWithCountryCode(personInfo) {
      const util = PhoneNumberUtil.getInstance();

      let result = ''

      try {
        const country = personInfo.phoneCountry.iso2;
        const parsed = util.parse(personInfo.contactPhone, country);
        const code = parsed.getCountryCode();
        const number = parsed.getNationalNumber();
        result = '+' + code + number
      } catch (error) {
        result = personInfo.phoneCountry.dialCode + personInfo.contactPhone
      }

      return result;
    }
  },
}
</script>

<style lang="scss" scoped>
.summary-form {
  margin-top: 25px;

  &__wrapper {
    padding: 20px;
  }

  &__row {
    display: flex;
    margin-bottom: 15px;
  }

  &__label {
    width: 300px;
  }

  &__errors {
    clear: both;
    color: darkred;
    font-size: 12px;
    display: block;
  }
}
</style>

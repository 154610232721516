<template>
  <section class="sponsor-activation">
    <SpaceDescription>
      <Headline :size="1">Request Partner Activation</Headline>
    </SpaceDescription>

    <Tabs
      :tabs="tabs"
      :activeTab="activeTab"
    >
      <component :is="activeTab" @tabchange="changeTab" />
    </Tabs>

  </section>
</template>

<script>
import EventInfo from "./SponsorActivationEventInfo.view";
import ActivationInfo from "./SponsorActivationInfo.view";
import Summary from "./SponsorActivationSummary.view";

import SpaceDescription from "@/components/SpaceDescription/SpaceDescription.component";
import Headline from "@/components/Headline/Headline.component";
import Paragraph from "@/components/Paragraph/Paragraph.component";
import Tabs from "@/components/Tabs/Tabs.component";
import Tab from "@/components/Tabs/Tab.component";
import get from "lodash.get";

export default {
  components: {
    SpaceDescription,
    Headline,
    Paragraph,
    ActivationInfo,
    EventInfo,
    Summary,
    Tabs,
    Tab
  },
  data: () => ({
    tabs: [
      {title: 'Event Information', tab: 'EventInfo'},
      {title: 'Activation Information', tab: 'ActivationInfo'},
      {title: 'Summary', tab: 'Summary'}
    ],
  }),
  computed: {
    selectedSeason() { return get(this, '$store.state.event.selectedSeason') },
    activeTab() { return get(this, '$store.state.sponsorActivation.activeTab') },
  },
  watch: {
    selectedSeason: {
      handler: function(newVal) {
        if(newVal) { this.getActivations() }
      },
      immediate: true
    },
  },
  methods: {
    getActivations() { this.$store.dispatch('sponsorActivation/getActivations') },
    changeTab(tab) { this.$store.commit('sponsorActivation/changeActiveTab', tab) }
  },
}
</script>

<style lang="scss">
.sponsor-activation {}
</style>

<template>
  <p class="paragraph-component">
    <span v-if="lorem">{{ text }}</span>
    <slot v-else/>
  </p>
</template>

<script>

export default {
  name: "DDMCAF-Paragraph",
  props: {
    lorem: {type: [Boolean, Number], default: false}
  },
  data: () => ({text: ''}),
  methods: {
    async generateLoremIpsum(length) {
      const parsedLength = Number(length);
      const faker = await import('faker');
      return faker.lorem.paragraphs(parsedLength);
    }
  },
  async created() {
    if (this.lorem) this.text = await this.generateLoremIpsum(this.lorem);
  }
}
</script>

<style scoped>
.paragraph-component {
  padding-bottom: 15px;
  font-size: 12px;
  color: #8F8F8F;
  line-height: 20px;
}
</style>

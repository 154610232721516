<template>
  <section class="space-description">
    <slot />
  </section>
</template>

<script>
export default {
name: "DDMCAF-SpaceDescription",
}
</script>

<style lang="scss" scoped>
  .space-description {
    margin-bottom: 30px;

    > p:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    > ::v-deep p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
</style>

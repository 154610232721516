<template>
  <label class="checkbox">
    <input
      class="checkbox-input"
      type="checkbox"
      :value="value"
      :name="name"
      :checked="isChecked"
      @change="emmitClick"
    />
    {{ label }}
  </label>
</template>

<script>
export default {
  name: "Checkbox.component",
  props: ['name', 'value', 'label', 'isChecked'],
  methods: {
    emmitClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;

  &-input {
    position: static !important;
    margin-top: auto !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
}
</style>